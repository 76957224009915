var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-excel"},[(_vm.showCount && _vm.count != null)?_c('span',{staticClass:"count-uploaded-items",class:{ 'count-uploaded-items-facture': _vm.hiddenContent },on:{"click":function($event){_vm.count = null;
      _vm.$emit('searchWithNumDossier', null);}}},[_vm._v(_vm._s(_vm.count))]):_vm._e(),(_vm.loader)?_c('div',{staticClass:"chargement chargement-loading-icon loader-uploaded-items",class:{ 'loader-circle': _vm.hiddenContent }},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"container",class:{ 'hidden-container-style': _vm.hiddenContent },on:{"click":function($event){_vm.count = null;
      _vm.$emit('searchWithNumDossier', null);}}},[_c('div',{staticClass:"file-wrapper",class:{
        'succes-file-excel': _vm.value != null,
        'hidden-file-style': _vm.hiddenContent
      }},[_c('input',{attrs:{"type":"file","name":"file-input","id":"uploadInput","disabled":_vm.showCount && _vm.loader},on:{"change":_vm.handleFileInput}}),(!_vm.hiddenContent)?_c('div',{staticClass:"upload_label"},[_c('font-awesome-icon',{attrs:{"icon":"cloud-upload-alt"}})],1):_c('v-tooltip',{attrs:{"bottom":"","color":"#311B92"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"block-icon mr-2"},on),[_c('IconClouduploadAlt')],1)]}}])},[_c('span',[_vm._v("Upload facture")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }